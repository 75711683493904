import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector  } from 'react-redux'
import MessengerDrawer from '../features/views/Chat'
import ListView from '../features/views/Threads'
import NewThreadView from '../features/views/NewThread'
import { viewContext } from '../context/viewContext'
import { VIEWS } from '../features/views/views'
import { apiSlice, selectUnreadMessageCount, useGetInitialLoadQuery } from '../features/api/apiSlice'
import { userContext } from '../context/userContext'
import { usePaginate } from '../common/usePaginate'
import { updateOwner as updateNewComments } from '../features/new_messages'

const MessengerWrapper = ({ page, setPage, setTotal, PaginationComponent, setActiveThreadId, activeThreadId, drawerOpen, setDrawerOpen, totalUnreadMessages, setTotalUnreadMessages, setOpenMessenger }) => {
  const dispatch = useDispatch()
  const { currentView, unique_owner_id, threadsPerPage, setFocusId } = useContext(viewContext)
  const [shouldRefetch, setShouldRefetch] = useState(false)
  const [messagesPage, setMessagesPage] = useState({})

  // close handler for drawer
  const handleClose = useCallback(() => {
    if (shouldRefetch) {
      dispatch(
        apiSlice.endpoints.getInitialLoad.initiate(
          { unique_owner_ids: [unique_owner_id], per_page: threadsPerPage, page: 1 },
          { subscribe: false, forceRefetch: true }
        )
      )
      setPage(1)
    }
    setShouldRefetch(false)
    setDrawerOpen(false)
    setActiveThreadId(null)
    setFocusId(null)
  }, [shouldRefetch, unique_owner_id])

  return (
    <div className="messenger-wrapper">
      <div className="dome-relative">
        {currentView === VIEWS.LIST_VIEW && (
          <MessengerDrawer
            activeThreadId={activeThreadId}
            drawerOpen={drawerOpen}
            onClose={handleClose}
            shouldRefetch={shouldRefetch}
            setShouldRefetch={setShouldRefetch}
            threadPage={page}
            page={messagesPage}
            setPage={setMessagesPage}
          />
        )}
        <div className={`messenger dome-light-shadow dome-rounded-border dome-full-width ${drawerOpen ? 'open' : ''}`}>
          {currentView === VIEWS.LIST_VIEW && (
            <ListView
              setActiveThreadId={setActiveThreadId}
              drawerOpen={drawerOpen}
              setDrawerOpen={setDrawerOpen}
              page={page}
              setThreadPage={setPage}
              setTotal={setTotal}
              PaginationComponent={PaginationComponent}
              setMessagesPage={setMessagesPage}
              totalUnreadMessages={totalUnreadMessages}
              setTotalUnreadMessages={setTotalUnreadMessages}
              setOpenMessenger={setOpenMessenger}
            />
          )}
          {currentView === VIEWS.NEW_MESSAGE_VIEW && <NewThreadView page={page} />}
        </div>
      </div>
    </div>
  )
}

const MessengerToggle = ({ handleClick, page, openMessenger }) => {
  const { unique_owner_id } = useContext(viewContext)
  const totalUnreadMessages = useSelector(state => state.new_messages?.data[unique_owner_id] || 0)

  const [hovered, setHovered] = useState(false)
  const messageIconClasses = ['fa-messages']
  const caretStyle = {
    position: 'absolute',
    top: '20px',
    right: '25px',
    fontSize: '17px',
    color: 'var(--disabled)'
  }
  messageIconClasses.push(totalUnreadMessages > 0 ? 'dome-color-aqua' : 'dome-color-dark-grey')
  messageIconClasses.push(hovered ? 'fas' : 'fal')
  const unreadCountText = totalUnreadMessages > 9 ? `9+` : `${totalUnreadMessages}`

  const handleMouseEnter = () => setHovered(true)
  const handleMouseLeave = () => setHovered(false)

  return (
    <div className="dome-d-flex dome-justify-end dome-align-center" style={{ paddingInline: '17px' }}>
      <div
        className="dome-d-flex dome-gap3 dome-align-center"
        style={{ cursor: 'pointer' }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        <i className={messageIconClasses.join(' ')} style={{ fontSize: '20px' }} />
        {totalUnreadMessages > 0 && <span className="dome-color-aqua">{`${unreadCountText} new`}</span>}
        {openMessenger && <i className="fa-solid fa-caret-up" style={caretStyle}></i>}
      </div>
    </div>
  )
}

const App = ({defaultOpen = false, newMessages =  null}) => {
  const { threadsPerPage, unique_owner_id, focusId } = useContext(viewContext)
  const { page, setPage, setTotal, Component: PaginationComponent } = usePaginate({ perPage: threadsPerPage })
  const [totalUnreadMessages, setTotalUnreadMessages] = useState(null)
  const [openMessenger, setOpenMessenger] = useState(focusId ? true : defaultOpen)
  const [activeThreadId, setActiveThreadId] = useState(null)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (newMessages)
      dispatch(updateNewComments({ owner: unique_owner_id, count: newMessages }))
  }, [newMessages, unique_owner_id])

  const handleClick = useCallback(() => {
    setOpenMessenger(!openMessenger)

    if (openMessenger) {
      dispatch(apiSlice.util.invalidateTags(['Message']))
    }
  }, [openMessenger, dispatch])


  return (
    <div className="dome-relative">
      <MessengerToggle
        unique_owner_id={unique_owner_id}
        handleClick={handleClick}
        page={page}
        totalUnreadMessages={totalUnreadMessages}
        openMessenger={openMessenger}
      />
      {openMessenger && (
        <MessengerWrapper
          page={page}
          setPage={setPage}
          setTotal={setTotal}
          PaginationComponent={PaginationComponent}
          activeThreadId={activeThreadId}
          setActiveThreadId={setActiveThreadId}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          totalUnreadMessages={totalUnreadMessages}
          setTotalUnreadMessages={setTotalUnreadMessages}
          setOpenMessenger={setOpenMessenger}
        />
      )}
    </div>
  )

}

export default App
