import React, { forwardRef, useCallback, useContext, useMemo, useState } from "react"
import { viewContext } from "../context/viewContext"
import { useFetchParticipantsQuery } from "../features/api/apiSlice"

const ChipDropdown = ({ choiceChipData, hovered, userList, maxOptionsDisplayed, isExpanded, handleExpand }) => {
  const displayedOptions = isExpanded ? userList : userList?.slice(0, maxOptionsDisplayed)

  return (
    hovered && (
      <div className="user-choice-chip-dropdown dome-absolute dome-scrollable" style={{boxShadow: hovered ? 'var(--light-shadow)' : 'none'}}>
        {displayedOptions?.map((user) => (
          <div key={user.id} className="user-info">
            <div className="dome-p3">{user.full_name}</div>
            <div className="dome-p4 dome-color-med-grey">{user.role}</div>
          </div>
        ))}
        {userList?.length > maxOptionsDisplayed && (
          <div
            key={`${choiceChipData.value}--show-more`}
            className="dome-color-aqua dome-text-w500"
            style={{ cursor: 'default' }}
            onClick={handleExpand}
          >
            {`show ${!isExpanded ? 'more' : 'less'}`}
          </div>
        )}
      </div>
    )
  )
}

export const ChoiceChip = forwardRef(({ choiceChipData, userList, customLabel, classes, onClick, icon, alwaysShowIcon = false, maxOptionsDisplayed = 5 }, ref) => {
  const { unique_owner_id } = useContext(viewContext)
  const [hovered, setHovered] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const selectorClasses = ['recipient-choice-chip-selector', 'dome-d-flex', 'dome-rounded-border']
  selectorClasses.push(choiceChipData.user || !userList?.length ? 'no-dropdown' : '')

  const mergedClasses = [
    ...selectorClasses,
    ...(Array.isArray(classes) ? classes : classes ? classes.split(' ') : [])
  ]

  const defaultLabel = useMemo(() => (
    <div className="dome-d-flex dome-align-center dome-gap6">
      <span>
        {choiceChipData.label} {!choiceChipData.role && userList?.length > 0 && <>({userList?.length})</>}
      </span>
      {(alwaysShowIcon || hovered) && icon}
    </div>
  ), [choiceChipData, userList, alwaysShowIcon, hovered, icon])

  const handleMouseEnter = () => {
    if (choiceChipData?.user || !userList?.length) return

    setHovered(true)
  }
  const handleMouseLeave = () => setHovered(false)
  const handleExpand = () => setIsExpanded(!isExpanded)
  const handleClick = useCallback((item) => {
    if (!onClick) return

    onClick(item)
    setHovered(false)
    setIsExpanded(false)
  }, [onClick])

  return (
    <div ref={ref} className="recipient-choice-chip-container dome-relative" key={choiceChipData.value} onMouseLeave={handleMouseLeave}>
      <div onClick={() => handleClick(choiceChipData)} onMouseEnter={handleMouseEnter} className={mergedClasses.join(' ')} style={{boxShadow: hovered ? 'var(--light-shadow)' : 'none'}}>
        {customLabel ?? defaultLabel}
      </div>
      <ChipDropdown
        choiceChipData={choiceChipData}
        hovered={hovered}
        userList={userList}
        maxOptionsDisplayed={maxOptionsDisplayed}
        isExpanded={isExpanded}
        handleExpand={handleExpand}
      />
    </div>
  )
})

ChoiceChip.displayName = 'ChoiceChip'
